import LazyLoad from "vanilla-lazyload";
import Swiper, {Autoplay, Pagination, Navigation, Lazy} from 'swiper/core';
import Alpine from 'alpinejs';
import Tingle from 'tingle.js';
import { getCurrentWeekday, isOpeningHour, getCurrentTime, convertHourToMillisec } from './openingHoursCalc.js';

// Global variables
const currentDate = new Date();


// configure Swiper to use modules
Swiper.use([Autoplay, Pagination, Navigation, Lazy]);

const swiper = new Swiper('.swiper-container', {
    direction: 'horizontal',
    loop: true,
    lazy: {
        loadPrevNext: true,
    },
    speed: 600,
    autoplay: {
        delay: 5000,
    },
    pagination: {
        el: '.avk-pagination',
        type: 'bullets',
        clickable: true
    },
    navigation: {
        nextEl: '.avk-button-next'
    },
    allowTouchMove: false
});

// Alpine
window.Alpine = Alpine

Alpine.start();

// Tingles
const tingleModals = document.querySelectorAll('.js-tingle-modal');

tingleModals.forEach((modalElement) => {
    parentEl = modalElement.parentElement;
    contentEl = parentEl.querySelector('.js-tingle-content');

    var modal = new Tingle.modal({
         closeMethods: ['overlay', 'button', 'escape'],
         closeLabel: "Close",
    });
    
    modal.setContent(contentEl.innerHTML);

    modalElement.addEventListener('click', function(){
        modal.open();
    });
});

// Date calc
const openings = [];
const openingDays = document.querySelectorAll('.js-opening-day');

openingDays.forEach((el) => {
    const openingDay = el.innerText;
    const openingTime = el.nextElementSibling.innerText;
    
    if(openingDay.includes(",")) {
        let openingDayArr = openingDay.split(",");

        openingDayArr.forEach(openingDayEL => {
            // Build array + set per day
            let openingDayAndTime = [
                openingDayEL.trim()
            ]
            let timesPerDay = []

            // Times per day
            let openingHours = openingTime.split("\n"); 
            
            openingHours = openingHours.forEach((openingHourEl) => {
                openingHourEl = openingHourEl.split("–");
                let timesPerBlock = [];
                openingHourEl.forEach((el) => {
                    timesPerBlock.push(convertHourToMillisec(el));
                }); 
                timesPerDay.push(timesPerBlock);
            });
            openingDayAndTime.push(timesPerDay);


            openings.push(openingDayAndTime);

            return;      
        });
    } else {
        let tempOpeningTimes = openingTime.split("\n");
        let allOpeningHoursArr = [];
        let openingDayAndTime = [
            openingDay.trim()
        ]

        tempOpeningTimes.forEach((el) => {
            let tempOpeningHoursArr = el.split("–");
            let openHoursArr = [];
            
            tempOpeningHoursArr.forEach((tempOpeningHourEl) => {  
                openHoursArr.push(convertHourToMillisec(tempOpeningHourEl));
            });

            allOpeningHoursArr.push(openHoursArr);
        });
        openingDayAndTime.push(allOpeningHoursArr);

        openings.push(openingDayAndTime);
        return;
    }
    
});

const currentWeekday = getCurrentWeekday(currentDate);
const currentTime = getCurrentTime(currentDate);
const isOpeningHourBol = isOpeningHour(openings, currentWeekday, currentTime);

if(isOpeningHourBol) {
    const available = document.querySelector('.js-available');
    const notAvailable = document.querySelector('.js-not-available');
    available.classList.remove('hidden');
    notAvailable.classList.add('hidden'); 
}

// show floating btn
const floatingBtn = document.querySelector('.js-floating-button');

document.addEventListener('scroll', (e) => {
    if(window.scrollY > 0) {
        floatingBtn.style.opacity = "1";
    } else {
        floatingBtn.style.opacity = "0";
    }
})

// lazy animation
window.lazyFunctions = {
    fadeIn: function (el) {
        el.style.opacity = "1";
        el.style.transform = "translateY(0px)"; 
    }
} 

function executeLazyFunction(element) {
    var lazyFunctionName = element.getAttribute("data-lazy-function");
    var lazyFunction = window.lazyFunctions[lazyFunctionName];
    if (!lazyFunction) return;
    lazyFunction(element);
}
  
var ll = new LazyLoad({
    unobserve_entered: true,
    callback_enter: executeLazyFunction,
    threshold: 0
});