const days = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

export function getCurrentWeekday(currentDate) {
    return days[currentDate.getDay()];
}

export function getCurrentTime(currentDate) {
    const hours = currentDate.getHours();  
    const minutes = currentDate.getMinutes();  

    console.log("Current time: " + hours + ':' + minutes);

    const hoursToMilli = hours * (60000 * 60);
    const minutesToMilli = minutes * 60000;

    return hoursToMilli + minutesToMilli; 
}

export function convertHourToMillisec(openingHour) {
    const timeParts = openingHour.split(":");

    return (+timeParts[0] * (60000 * 60)) + (+timeParts[1] * 60000);
}

export function isOpeningHour(openingHours, currentWeekday, currentTime) {
    let foundedWeekday = openingHours.find(weekday => weekday[0] == currentWeekday);
    let isOpeningHour = false;

    if(foundedWeekday !== undefined) {
        foundedWeekday[1].forEach(foundedTime => {       
            if(foundedTime[0] <= currentTime && foundedTime[1] >= currentTime) {
                isOpeningHour =  true;
            }
        });
    } else {
        isOpeningHour =  false;
    }
    
    
    return isOpeningHour;
}


